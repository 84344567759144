














import { Route } from 'node_modules/vue-router/types';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SKRouterView extends Vue {
  public transitionName = 'slide-bottom';
  public transitionMode: string | null = 'out-in';

  get keepAlive(): boolean {
    return Object.keys(this.$route.params).length === 0;
  }

  get fullscreen(): boolean {
    return this.$route.meta.fullscreen;
  }

  created() {
    this.$router.beforeEach((to: Route, from: Route, next: Function) => {
      const toDepth = this.getDepth(to);
      const fromDepth = this.getDepth(from);
      const toPath = to.fullPath.split('/').slice(0, 2);
      const fromPath = from.fullPath.split('/').slice(0, 2);

      this.transitionMode = null;

      if (to.meta.fullscreen) {
        this.transitionMode = 'in-out';
        this.transitionName = 'slide-fullscreen';
      } else if (from.meta.fullscreen) {
        this.transitionMode = 'in-out';
        this.transitionName = 'slide-fullscreen';
      } else if (fromPath.join('/') !== toPath.join('/')) {
        this.transitionMode = null;
        this.transitionName = 'slide-bottom';
      } else {
        this.transitionMode = null;
        this.transitionName =
          toDepth < fromDepth ? 'slide-right' : 'slide-left';
      }

      if (to.name === 'search') {
        this.$store.commit('searchOpenedFrom', from);
      }
      next();
    });
  }

  private getDepth(route: Route): number {
    let path = route.fullPath;
    if (path.endsWith('/')) path = path.slice(0, -1);
    return path.split('/').length;
  }
}
