






















import { Vue, Component } from 'vue-property-decorator';
import { ISKNotification } from '@/utils/interfaces';
import { EventBus } from '@/utils/eventbus';

@Component
export default class SKNotification extends Vue {
  public notification: ISKNotification | null = null;

  public timer: number | undefined = undefined;

  public goto() {
    if (this.notification && this.notification.to) {
      this.$router.push(this.notification.to);
      this.reset();
      this.nextNotification();
    }
  }

  public nextNotification() {
    this.notification = this.$store.state.notifications.shift();

    if (!this.notification && this.timer) {
      this.reset();
      return;
    }

    this.timer = setTimeout(() => {
      this.nextNotification();
    }, 7000);
  }

  public reset(): void {
    clearTimeout(this.timer);
    this.timer = undefined;
  }

  mounted() {
    EventBus.$on('message', () => {
      if (!this.timer) this.nextNotification();
    });
  }

  beforeDestroy() {
    if (this.timer) {
      this.reset();
    }
  }
}
