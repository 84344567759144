
























import { EventBus } from '@/utils/eventbus';
import { IPosition } from '@/utils/interfaces';
import { getText } from '@/utils/langs';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SKSelectPosition extends Vue {
  public modalOpened = false;
  public query = '';

  mounted() {
    EventBus.$on('sk-select-position', () => {
      this.query = '';
      this.modalOpened = true;
    });
  }

  public selected(id: string) {
    EventBus.$emit('sk-select-position-return', id);
    this.modalOpened = false;
  }

  get positions(): IPosition[] {
    const positions: IPosition[] = this.$store.getters.positions;
    if (this.query.length === 0) return positions;
    return positions.filter(x =>
      getText('position.' + x._id + '.title')
        .toLowerCase()
        .includes(this.query.toLowerCase())
    );
  }
}
