































import { EventBus } from '@/utils/eventbus';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SKSuspensionInfo extends Vue {
  public modalOpened = false;
  public userId = '';

  mounted() {
    EventBus.$on('sk-suspension-info', (id: string) => {
      this.userId = id;
      this.modalOpened = true;
    });
  }

  public close() {
    this.modalOpened = false;
  }
}
