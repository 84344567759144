










import { Vue, Component } from 'vue-property-decorator';
import SKNotification from './components/SK-Notification.vue';
import SKRouterView from './components/SK-RouterView.vue';
import SKSuspensionInfo from './components/SK-SuspensionInfo.vue';
import SKSelectPosition from './components/SK-SelectPosition.vue';
import SKTabbar from './components/SK-Tabbar.vue';

@Component({
  components: {
    SKRouterView,
    SKSuspensionInfo,
    SKNotification,
    SKSelectPosition,
    SKTabbar
  }
})
export default class App extends Vue {}
